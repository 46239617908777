@import "../../styles/helpers/global";

.page-wrapper {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.chart-grid {
  display: flex;
  gap: $gap;
  max-height: 60vh;

  .filters-wrapper {
    display: flex;
    flex-direction: column;
    gap: $gap;
    width: 180px;
    height: inherit;
  }

  .chart-wrapper {
    // @extend .shadow-wrapper;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 10px $mmc-blue-20, 0px 3px 10px $mmc-blue-20;
    // box-shadow: inset 0px 3px 10px #00000029, 0px 3px 10px #00000029;
    border-radius: $border-radius-16;
    width: 100%;
  }

  .chart-table-data {
    width: 100%;
  }
}

.detailed-table {
  width: 100%;
}

.epics-table {
  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    font-weight: 300;
    border-color: $border-table;
    color: $dark-blue;
    max-width: 250px;
  
    &:first-child {
      border-left: 2px solid transparent;
      transition: $transition;
    }
  
    div.table-cell {
      max-width: inherit !important;
      // min-width: 100px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.ant-table-cell-row-hover {
      &:first-child {
        border-left: 2px solid $bright-orange;
      }
    }
  }

  .outer-table-container {
    padding-top: 0;
  
    .table-outer-filters {
      padding-top: 15px;
    }
  }

  .clear-filters {
    background: none;
    border: none;
    color: $dark-blue;
    // margin: 15px 0 0 15px;

    &:hover {
      color: $bright-orange;
    }
  }
}