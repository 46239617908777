@import '../variables'; 

.shadow-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 10px $mmc-blue-20, 0px 3px 10px $mmc-blue-20;
  border-radius: $border-radius-16;
  // width: fit-content;
}

.section-title {
  color: $dark-blue;
  // font-size: $font-size-25;
  font-size: $font-size-22;
}

// TODO: add line-height
.container-header {
  font-family: "Poppins Semibold";
  // font-size: $font-size-35;
  font-size: $font-size-22;
  color: $dark-blue !important;
}

canvas { 
  width: 100% !important;
}

table {
  // font-size: $font-size-22;
  font-size: 16px;
  line-height: 1.18;
  color: $dark-blue;

  .ant-table {
    background: none;
    padding: 15px;
  }
}
