// Colors
$black: #000000;
$white: #ffffff;
$linkColor: #336bb4;

$primary: #87ca97;
$secondary: #63c9d9;

$info: #009efb;
$lightInfo: #cfecfe;

$success: #55ce63;
$lightSuccess: #e8fdeb;

$darken: #212529;
$lightDarken: #ddd;

$warning: #ffbc34;
$lightWarning: #fff8ec;

$danger: #f62d51;
$lightDanger: #f9e7eb;

$textColor: #2c2b2e;
$textColorDarken: #212529;

$grey: #d2cccc;
$grey-light: #f7f9f9;
$grey-light-lighter: #fcfcfc;
$grey-light2: #e8edef;
$grey-light3: #dae0e4;
$grey-darken: #46575f;
$text-muted: #90a4ae;

$mmc-blue: #3F0FE5; // > header, button, lines/tables
$mmc-blue-20: #3F0FE533; // // mmc-blue 20% opacity > box shadow
$dark-blue: #1F0281; // > text on white background, icons
// dark-blue 20% opacity
$dark-blue-20: #1F028133; // > text in fields
// $dark-blue 10% opacity
$graph-blue: #1F02811A; // > graph max value
$placeholder-blue: #1F02817F; // > placeholder blue 50% opacity

$bright-orange: #E3852C; // > hover & activated
$pure-red: #FC0000; // > only in table if value is higher than SP

/** 
Story point colors 
*/
$gray: #ECECEC;
$magenta-pink: #830051;
$pure-orange: #F0AB00;
$dark-cyan-blue: #004F8E;
$strong-pink: #D0006F;
$very-dark-blue: #003865;

/** 
Brand name colors
*/
$forxiga: #EF402F;
$lokelma: #67BEAA;
$brilique: #662368;
$ondexxya: #E10D1A;
$enhertu: #2E1353;
$lynparza: #004D74;
$imfinzi: #AE1925;
$calquence: #F15D22;
$tagrisso: #B8E222;
$diagnostik: #003869;
$saphnelo: #00AECA;
$bevespi: #E10069;
$tezspire: #E94370;
$fasenra: #AE2573;
$trixeo: #F0B323;
$symbicort: #FF0000;
$evusheld: #01CDB6;
$vaxzevria: #830051;
$synagis: #005590;
$allgemein: #003869; // > generally CVRM, V&I, R&I ... 

// Transitions
$transition: all 0.1s ease-in-out;

// Box radius
$border-radius-16: 16px; // containers

// Font sizes
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-25: 25px;
$font-size-28: 28px;
$font-size-35: 35px;

// Line spacing > transformed in unitless
// $line-spacing-24: 24px; 
// $line-spacing-26: 26px; 
// $line-spacing-27: 27px; 
// $line-spacing-30: 30px; 
// $line-spacing-35: 35px; 
// $line-spacing-37: 37px;
// $line-spacing-72: 72px;
// $line-spacing-96: 96px;

// Shadows
$shadow-dropdown: rgba(0, 0, 0, 0.05) 0 3px 12px 0;
$shadow-popover: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

// Borders
$border-color: #707070;
$border-table: #dedede;

// Inputs border radius
// $inputBorderRadius: 999px;
$inputBorderRadius: 4px; // > fields, graph & BU/brandname boxes
$inputBorderColor: #d9d9d9;

// Default font-family
$fontFamily: Rubik, sans-serif;

// Responsive breakpoints
$media-down-xxl: "(max-width: 1400px)";
$media-down-xl: "(max-width: 1200px)";
$media-down-x: "(max-width: 1024px)";
$media-down-lg: "(max-width: 992px)";
$media-down-md: "(max-width: 767px)";
$media-down-sm: "(max-width: 576px)";
$media-down-xs: "(max-width: 540px)";

//Margins

$gap: 15px;
