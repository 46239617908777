@import "../../styles/variables";
//.ant-input-search {
//  > .ant-input-group {
//    display: flex;
//
//    > .ant-input-group-addon:last-child {
//      display: flex;
//
//      .ant-input-search-button:not(.ant-btn-primary) {
//        min-height: auto;
//        padding: 0;
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        min-width: 35px;
//
//        .anticon {
//          position: static !important;
//          transform: none;
//        }
//      }
//    }
//  }
//}
//
//.ant-input-affix-wrapper:focus,
//.ant-input-affix-wrapper-focused {
//  border-color: $primary;
//  box-shadow: none;
//}
//
//.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//  border-color: $primary;
//}
//
//.ant-input:hover {
//  border-color: $primary;
//}
//
//.ant-input:focus,
//.ant-input-focused {
//  box-shadow: none;
//  border-color: $primary;
//}
//

.ant-input-affix-wrapper  {
  border-radius: $inputBorderRadius;
  border-color: $mmc-blue;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;

  &:hover {
    border-color: $bright-orange !important;
  }

  // TODO: doesn't work yet
  &:focus {
    box-shadow: 0 0 5px rgba(227, 133, 44, 1) !important;
    border: 1px solid $bright-orange !important;
  }

  .ant-input-clear-icon {
    color: $dark-blue;
  }
}

.ant-input {
  border: 1px solid $mmc-blue;
  border-radius: $inputBorderRadius;
  font-size: $font-size-16;
  // $dark-blue-20 too light
  color: $dark-blue;
  padding: 10px;
  line-height: 1.2;

  @include placeholder {
    font-family: "Poppins Regular";
    font-size: $font-size-16;
    color: $placeholder-blue;
  }

  &.ant-input-lg {
    padding: 10px 30px;
    // max-width: 250px;
    flex-grow: 1;

    @include placeholder {
      font-size: $font-size-16;
      line-height: 1.2;
      color: $placeholder-blue;
    }
  }
}

.ant-form-item-control-input-content {
  // max-width: 500px;
  flex-grow: 1;

  input {
    &:focus {
      box-shadow: 0 0 5px rgba(227, 133, 44, 1);
      border: 1px solid rgba(227, 133, 44, 1);
    }

    &:hover {
      border-color: $bright-orange;
    }
  }
}

.ant-form-item-with-help .ant-form-item-explain {
  max-width: 250px;
}
//
//.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
//.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
//  box-shadow: none;
//  border-color: $danger;
//}
//
//.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
//.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
//  border-color: $danger;
//}
//
//.ant-form-item-explain-error {
//  font-weight: 300;
//  color: $danger;
//  font-size: 14px;
//}
//
//.ant-form-item-has-error {
//  .input-upload {
//    border-color: $danger;
//  }
//}
//
//.ant-input-number {
//  width: 100%;
//  border-radius: $inputBorderRadius;
//
//  &:focus,
//  &.ant-input-number-focused {
//    box-shadow: none;
//    border-color: $primary;
//  }
//
//  &:hover {
//    border-color: $primary;
//  }
//
//  input {
//    font-weight: 300;
//    color: $textColor;
//    line-height: 1;
//
//    @include placeholder {
//      font-family: $fontFamily;
//      color: $grey;
//      font-weight: 300;
//      opacity: 0.8;
//      font-size: 16px;
//    }
//
//    &[disabled] {
//      color: rgba(0, 0, 0, 0.25);
//    }
//  }
//}
//
//.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
//.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
//  color: $primary;
//}
//
//.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
//.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
//  box-shadow: none;
//  border-color: $danger;
//}
