@import "../../styles/variables";

.ant-select {
  // min-width: 200px;
  // max-width: 250px;
  flex-grow: 1;
  width: 100% !important;
  font-size: $font-size-16;
}
  

.ant-select-selection {
  color: $dark-blue;
  box-shadow: 0 0 5px rgba(227, 133, 44, 1);
}

.ant-select-single .ant-select-selector {
  // TODO: doesn't work yet
  &:focus {
    box-shadow: 0 0 5px rgba(227, 133, 44, 1);
    border: 1px solid rgba(227, 133, 44, 1);
  }

  &:hover {
    border-color: $bright-orange !important;
  }

  .ant-select-selection-placeholder {  
    color: $placeholder-blue;
  }
}