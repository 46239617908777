.layout-container-outer {
  display: flex;
  flex-direction: column;
}

.layout-content-inner {
  padding: 20px;
  background-color: $white;
  min-height: 100%;
  flex: 1;
}

.layout-footer {
  background-color: $white;
  padding: 15px 20px;
  text-align: center;
  font-size: 12px;
}
