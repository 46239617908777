.modal-wrapper {
  &.modal-no-title {
    .modal-header {
      padding: 0;
      border: 0;
    }
  }

  &.large {
    .ant-modal {
      width: 1062px !important;
      max-width: 100%;
    }
  }

  &.medium {
    .ant-modal {
      width: 800px !important;
      max-width: 100%;
    }
  }

  &.small {
    .ant-modal {
      width: 600px !important;
      max-width: 100%;
    }
  }

  &.pre-large {
    .ant-modal {
      width: 850px !important;
      max-width: 100%;
    }
  }

  &.rounded {
    .ant-modal-content {
      border-radius: 20px;
    }
  }

  &.closable {
    .ant-modal-body {
      padding: 30px 15px 15px 15px;
    }
  }

  .modal-title {
    text-align: left;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #141314;
    border-bottom: 1px solid #bec0bb;
    padding-bottom: 12px;
  }

  .ant-modal-body {
    padding: 15px;
  }

  .modal-header {
    padding: 20px 0;
  }

  .modal-close {
    background-color: transparent;
    border: 0;
    color: #000;
    outline: none;
    cursor: pointer;
    padding: 2px 2px;
    font-size: 16px;
    position: absolute;
    right: 6px;
    top: 3px;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &:hover {
      opacity: 0.7;
    }
  }

  .ant-modal {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }
}

.ant-modal-confirm {
  .ant-modal-confirm-title {
    color: $textColorDarken;
    font-size: 18px;
    font-weight: 500;
  }

  .ant-modal-confirm-content {
    color: $textColor;
    font-weight: 300;
    font-size: 14px;
  }

  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
  }
}
