@import "../../styles/variables";
//$btn-primary: #87ca97;
//$btn-secondary: #63c9d9;
//$btn-danger: #e60a31;
//$btn-default: #868e96;
//
//// Disabled antd focus buttons animations
//[ant-click-animating-without-extra-node]:after {
//  -webkit-animation: none !important;
//  -moz-animation: none !important;
//  -o-animation: none !important;
//  -ms-animation: none !important;
//  animation: none !important;
//}
//
//.ant-btn {
//  border: 0;
//  text-shadow: none !important;
//  position: relative;
//  display: inline-flex;
//  align-items: center;
//  justify-content: center;
//  padding: 5px 38px;
//  min-width: 146px;
//  text-align: center;
//  line-height: 1;
//  font-size: 14px;
//  font-weight: 300;
//  transition: all 0.15s ease-in-out;
//  height: auto;
//  min-height: 40px;
//  color: #fff;
//  //background: transparent radial-gradient(closest-side at 50% 50%, #003865 0%, #ffffff 100%, #3e5970 100%) 0% 0%
//  //  no-repeat padding-box;
//  //box-shadow: 1px 1px 1px #00000031;
//  //border-radius: 6px;
//
//  &:focus,
//  &:active {
//    background-color: $btn-default;
//    color: #fff;
//  }
//
//  &:hover {
//    background-color: $btn-default;
//    color: #fff;
//    opacity: 0.7;
//  }
//
//  & > .anticon + span,
//  & > span + .anticon {
//    margin: 0;
//  }
//
//  .anticon {
//    position: absolute !important;
//    left: 14px;
//    top: 50%;
//    transform: translateY(-50%);
//    display: block;
//    font-size: 15px;
//
//    svg {
//      font-size: 15px;
//      display: block;
//      shape-rendering: auto !important;
//    }
//  }
//
//  &.icon-right {
//    .anticon {
//      left: auto;
//      right: 13px;
//    }
//  }
//}
//
//.ant-btn-sm {
//  min-height: 32px;
//}
//
//.ant-btn-lg {
//  min-height: 48px;
//}
//
//.ant-btn-round {
//  border-radius: 20px;
//}
//
//.ant-btn-primary {
//  background-color: $btn-primary;
//  color: #fff;
//
//  &:focus,
//  &:active {
//    background-color: $btn-primary;
//  }
//
//  &:hover {
//    background-color: $btn-primary;
//    opacity: 0.7;
//  }
//
//  &[disabled] {
//    color: #fff;
//    background-color: $btn-primary;
//    opacity: 0.5;
//
//    &:hover {
//      background-color: $btn-primary;
//      color: #fff;
//    }
//  }
//
//  &.ant-btn-background-ghost {
//    background-color: #fff;
//    border: 1px solid $btn-primary;
//    color: $btn-primary;
//
//    &:focus,
//    &:active {
//      border-color: $btn-primary;
//      background-color: #fff;
//      color: $btn-primary;
//    }
//
//    &:hover {
//      background-color: $btn-primary;
//      border-color: $btn-primary;
//      color: #fff;
//      opacity: 0.7;
//    }
//
//    &[disabled] {
//      color: $btn-primary;
//      border-color: $btn-primary;
//      background-color: #fff;
//      opacity: 0.5;
//
//      &:hover {
//        color: $btn-primary;
//        border-color: $btn-primary;
//        background-color: #fff;
//      }
//    }
//  }
//}
//
//.ant-btn-secondary {
//  background-color: $btn-secondary;
//  color: #fff;
//
//  &:focus,
//  &:active {
//    color: #fff;
//    background-color: $btn-secondary;
//  }
//
//  &:hover {
//    color: #fff;
//    background-color: $btn-secondary;
//    opacity: 0.7;
//  }
//
//  &[disabled] {
//    color: #fff;
//    background-color: $btn-secondary;
//    opacity: 0.5;
//
//    &:hover {
//      background-color: $btn-secondary;
//      color: #fff;
//    }
//  }
//
//  &.ant-btn-background-ghost {
//    background-color: #fff;
//    border: 1px solid $btn-secondary;
//    color: $btn-secondary;
//
//    &:focus,
//    &:active {
//      border-color: $btn-secondary;
//      background-color: #fff;
//      color: $btn-secondary;
//    }
//
//    &:hover {
//      background-color: $btn-secondary;
//      border-color: $btn-secondary;
//      color: #fff;
//      opacity: 0.7;
//    }
//
//    &[disabled] {
//      color: $btn-secondary;
//      border-color: $btn-secondary;
//      background-color: #fff;
//      opacity: 0.5;
//
//      &:hover {
//        color: $btn-secondary;
//        border-color: $btn-secondary;
//        background-color: #fff;
//      }
//    }
//  }
//}
//
//.ant-btn-danger {
//  background-color: $btn-danger;
//  color: #fff;
//
//  &:focus,
//  &:active {
//    color: #fff;
//    background-color: $btn-danger;
//  }
//
//  &:hover {
//    color: #fff;
//    background-color: $btn-danger;
//    opacity: 0.7;
//  }
//
//  &[disabled] {
//    color: #fff;
//    background-color: $btn-danger;
//    opacity: 0.5;
//
//    &:hover {
//      background-color: $btn-danger;
//      color: #fff;
//    }
//  }
//
//  &.ant-btn-background-ghost {
//    background-color: #fff;
//    border: 1px solid $btn-danger;
//    color: $btn-danger;
//
//    &:focus,
//    &:active {
//      border-color: $btn-danger;
//      background-color: #fff;
//      color: $btn-danger;
//    }
//
//    &:hover {
//      background-color: $btn-danger;
//      border-color: $btn-danger;
//      color: #fff;
//      opacity: 1;
//    }
//
//    &[disabled] {
//      color: $btn-danger;
//      border-color: $btn-danger;
//      background-color: #fff;
//      opacity: 0.5;
//
//      &:hover {
//        color: $btn-danger;
//        border-color: $btn-danger;
//        background-color: #fff;
//      }
//    }
//  }
//}

// .submit-button {
//     font-size: $font-size-22;
//     line-height: 1.09;
//     border-radius: $inputBorderRadius;
//     height: 50px;
//     box-sizing: border-box;
// }

.submit-button {
    background-color: $mmc-blue;
    border-color: $mmc-blue;
    border-radius: $inputBorderRadius;
    font-family: "Poppins Semibold";
    // font-size: $font-size-22;
    font-size: 16px;
    // line-height: 1.09;
    // height: 50px;
    box-sizing: border-box;
    // height: auto !important;
  
    &:hover {
      background-color: $bright-orange;
      border-color: $bright-orange;
    }
}
