@import "../../styles/variables";

$border: 2px solid $mmc-blue;
$separator: 2px solid $mmc-blue-20;

.ant-table-wrapper.story-points-table-wrapper {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td  {
        line-height: 1.2; // 24px
        border: none;

        &::before {
            width: 0;
            height: 0;
            display: none;
        } 
    }

    .ant-table-tbody {
        & > tr:last-of-type {
            font-family: "Poppins Semibold";
        }

        .verfugbar-filter {
            font-family: "Poppins Semibold";
            color: $dark-blue;
        }
    }

    .ant-table-thead {
        & > tr:first-of-type > th.ant-table-cell:first-child {
            text-align: end;
        }
    }

    .ant-table-cell {
        padding: 5px 0;
        text-align: center;

        &.ant-table-cell-row-hover {
            pointer-events: none;
        }

        .table-cell-out-of-range {
            color: $pure-red;
        }
    }

    div.table-cell.row {
        display: flex;
        justify-content: space-between;
        border-width: 1px;
        border-style: solid;        
        border-radius: 9px;
        box-sizing: border-box;
        padding: 0 10px;
        line-height: 1.2;

        .cell-item {
            text-align: left;
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &::before {
        display: none;
    }

    .total-row {
        font-family: "Poppins Semibold" ;
    }
}

.verfugbar-sp {
    color: white;
    width: unset;
    min-width: 150px;
    max-width: 200px;
}

div.story-points-table-wrapper.planist-story-points-wrapper.sprintplanung-sp .ant-table-tbody > tr > td {
    min-width: 80px;
}

// div.story-points-table-wrapper.planist-story-points-wrapper.dashboard-sp .ant-table-tbody > tr > td {
//     min-width: 80px;
// }

div.story-points-table-wrapper.planist-story-points-wrapper {
    thead.ant-table-thead {
        tr:not(:first-of-type) {
            th:nth-of-type(odd) {
                border-left: $border;
                border-right: $separator;
            }

            th:last-of-type {
                border-right: $border;
            }
        }

        tr > th {
            font-size: 14px;
        }
    }

    .ant-table-thead > tr > th:first-of-type {
        max-width: 200px !important;
    }

    // .ant-table-thead > tr > th, 
    .ant-table-tbody > tr > td  {
        min-width: 65px;
        font-size: 16px;
        // max-width: 250px !important;
    }
    
    .ant-table-tbody {
        tr:not(:first-child) {
            td:nth-of-type(even):not(:last-of-type) {
                border-left: $border;
                border-right: $separator;
            }
            
            td:last-of-type {
                border-right: $border;
            }
        }
    }

    .ant-table-cell {
        align-items: center;
        padding: 3px;
    }
}

.table-grid {
    display: flex;
    gap: $gap;
    height: fit-content;

    .filters-wrapper {
        display: flex;
        flex-direction: column;
        gap: $gap;
    }

    div.filters-wrapper .filter-container {
        height: 100%;
        max-width: 250px;
    }

    .detailed-table {
        width: calc(100% - 250px);
    }

    .ant-table-body {
        @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
    
        .ant-table-tbody > tr:last-of-type {
            font-family: "Poppins Regular";
        }
    }
}