@import "../../styles/variables";

.credentials-form {
  // margin: 200px auto;
  padding: 60px 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -49%);
}

.credentials-form-actions {
  // display: flex;
  // justify-content: space-between;
  // padding: 0 20px;

  display: flex;
  justify-content: flex-end;

  .reset-link {
    font-size: $font-size-16;
    line-height: 1.18;
    color: $dark-blue;
  }
}

.forgot-form {
  justify-content: flex-end;
}
