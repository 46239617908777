@import-normalize;
@import "./fonts";
@import "~antd/dist/antd.css";
@import "variables";
@import "./helpers/all";
@import "./components";

// Example how to use media queries
body {
  font-size: 16px;
  font-family: "Poppins Regular", Arial, sans-serif;
  color: $dark-blue;
}
