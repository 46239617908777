@import "../../styles/helpers/global";

.horizontal-form {
    @extend .shadow-wrapper;
    width: 100%;
    height: 190px;
    display: flex;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px 30px;
    height: fit-content;

    .ant-typography {
        @extend .section-title;
        margin: 0;
    }
}

form.ant-form.ant-form-inline {
    align-items: flex-start;
    gap: $gap;


    .ant-form-item {
        flex-grow: 1;
        margin: 0;
    }
}