@import "../../../styles/variables";

.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $mmc-blue;
  // height: 75px;
  max-height: 75px;
  letter-spacing: 0px;
  line-height: 1.75;
  color: white;

  .dashboard-item {
    color: white;
    font-family: "Poppins Bold";
    // font-size: $font-size-25;
    font-size: 24px;
    line-height: 1.2;
  }

  .header-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // TODO: fix
    width: 80%;
    overflow: hidden;

    .header-item {
      display: flex;
      align-items: center;
      margin: 0 15px;
    }
  }
}

.logo {
  height: 30px;
  width: 30px;
  background: white 0% 0% no-repeat padding-box;
}