.export-overlay {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  min-width: 150px;

  a {
    padding: 7px 20px;
    color: $textColor;

    span {
      margin-right: 10px;
      font-size: 16px;
    }

    &:hover {
      background-color: $grey-light;
    }
  }
}
