@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url("../fonts/Segoe UI.woff") format('woff');
}

@font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Italic'), url("../fonts/Segoe UI Italic.woff") format('woff');
}


@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url("../fonts/Segoe UI Bold.woff") format('woff');
}

@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url("../fonts/Segoe UI Bold Italic.woff") format('woff');
}

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Regular'), url("../fonts/Poppins Regular.woff") format('woff');
}

@font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Italic'), url("../fonts/Poppins Italic.woff") format('woff');
}

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold'), url("../fonts/Poppins Bold.woff") format('woff');
}

@font-face {
  font-family: 'Poppins Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold Italic'), url("../fonts/Poppins BoldItalic.woff") format('woff');
}

@font-face {
  font-family: 'Poppins Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Semibold'), url("../fonts/Poppins SemiBold.woff");
}