@import "../../../styles/variables";
@import "../../../styles/helpers/global";

.table-actions {
  display: flex;
  align-items: center;

  .delete {
    @extend .action-icon;
    margin: 0 10px;
  }
}

.users-management {
  padding: 20px 30px;
  width: 100%;

  .ant-typography {
    @extend .container-header;
    
    margin: 0;
  }
}

.ant-select-item-option-content {
  font-family: "Poppins Regular";
  font-size: $font-size-16;
  color: $dark-blue;
}

.action-icon {
  color: $dark-blue;
}