@import "./ExportDropdown/style";

.outer-table-container {
  // background-color: #fff;
  padding: 15px;
  // padding: 20px 10px;

  // .ant-input-search {
  //   .anticon {
  //     color: $white;

  //     &.anticon-close-circle {
  //       color: $grey;
  //     }
  //   }
  // }

  // .ant-table {
  //   background: none;
  // }

  .ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after, .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
    box-shadow: none;
  }

  .ant-table-pagination {
    padding-right: 15px;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      font-size: 14px;
      // font-size: $font-size-22;
      font-family: sans-serif;
    }
  }

  .ant-table-body {
    @include scrollbars(5px, rgba($grey, 0.7), rgba($grey, 0.1));
  }

  // --> moved outside to apply style on all tables
  // .ant-table-thead > tr > th {
  //   background: $white;
  //   white-space: nowrap;
  //   color: $dark-blue;
  // }

  //.ant-pagination-item {
  //  border-radius: 10px;
  //
  //  &:hover {
  //    border-color: $primary;
  //
  //    a {
  //      color: $primary;
  //    }
  //  }
  //}

  .ant-pagination-item-active {
   border-color: $mmc-blue;
  }
  
  .ant-pagination-item {
    border-radius: $inputBorderRadius;

    a {
      color: $dark-blue;
    }

    &:hover {
      border-color: $dark-blue;
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: $inputBorderRadius;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link:not([disabled]),
  .ant-pagination-next:hover .ant-pagination-item-link:not([disabled]) {
   border-color: $dark-blue;
   border-radius: $inputBorderRadius;
   color: $dark-blue;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: $dark-blue;
  }

  //.ant-table-column-sorter-up.active,
  //.ant-table-column-sorter-down.active,
  //.ant-table-filter-trigger.active {
  //  color: $white;
  //  background-color: $primary;
  //}
  
  .ant-table-filter-column {
    align-items: center;
  }

  .ant-table-column-sorter-inner {
    height: 20px;
    justify-content: space-between;

    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
      font-size: $font-size-14;
    }
  }

  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: $dark-blue;
  }

  .ant-table-filter-trigger {
    // border-radius: 5px;
    margin: -1px -6px -4px 4px;
    height: 25px;
    font-size: $font-size-14;
    color: $dark-blue;
  }

  td.ant-table-column-sort {
    background-color: $white;
  }

  &.loading {

    .table-left-actions {
      opacity: .5;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        z-index: 100;
      }
    }
  }
}

.ant-table-thead > tr > th {
  font-family: "Poppins Semibold";
  // font-size: $font-size-20;
  font-size: 16px;
  // line-height: 3.6;
  line-height: 1.5;
  white-space: nowrap;
  color: $dark-blue;
}

.ant-table-filter-dropdown {
  .ant-picker-range {
    display: flex !important;
  }
}

.table-actions-column {
  display: flex;
  align-items: center;

  a {
    margin-right: 10px;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  font-weight: 300;
  border-color: $border-table;
  color: $dark-blue;

  &:first-child {
    border-left: 2px solid transparent;
    transition: $transition;
  }

  &.ant-table-cell-row-hover {
    &:first-child {
      border-left: 2px solid $bright-orange;
    }
  }
}

.ant-table-tbody > tr.ant-table-row-selected {
  &:hover > td {
    background-color: lighten($secondary, 35%);
  }

  > td {
    background-color: lighten($secondary, 35%);
  }
}

//.ant-spin-dot-item {
//  background-color: $primary;
//}

.table-filter-dropdown {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 250px;

  .ant-space {
    margin-top: 10px;
  }
}

.table-outer-filters {
  padding: 0 15px;

  &.p-0 {
    padding: 0;
  }

  .search-filter {
    margin-right: 10px;
    margin-bottom: 10px;
    // width: 200px;
    width: 50%;

    // .ant-input-affix-wrapper .ant-input-affix-wrapper-lg {
    //   border-radius: $inputBorderRadius;
    // }
  }

  .table-right-actions {
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;

    .action-btn {
      margin: 0 15px 10px 0;
      min-width: 140px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .table-left-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;

    .table-external-filters {
      margin-right: 10px;
      margin-bottom: 10px;
      flex: 1;

      .ant-select {
        min-width: 200px;
        max-width: 250px;
      }
    }
  }
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  .ant-table-cell .ant-table-expanded-row-fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    width: 100% !important;
  }
}

.filter-range-date-picker {
  min-width: 300px;
  .ant-picker-input > input {
    font-size: 14px;
  }
}

.ant-table-cell {
  background: transparent !important;
}

.ant-select {
  color: $dark-blue;
  border-radius: $inputBorderRadius;

  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: $inputBorderRadius;
  }
}
